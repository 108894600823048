import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import SideMenuBar from './components/SideMenuBar/SideMenuBar';
import Calculadora from './pages/Calculadora/Calculadora';
import CarrinhoDeCompras from './pages/CarrinhoDeCompras/CarrinhoDeCompras';
import Carteira from './pages/Carteira/Carteira';
import Configs from './pages/Configuracoes/Configuracoes';
import ConfirmacaoInsercaoDeSaldo from './pages/ConfirmacaoInsercaoDeSaldo/ConfirmacaoInsercaoDeSaldo';
import Conta from './pages/Conta/Conta';
import ContaEditPassword from './pages/Conta/ContaEditPassword';
import ContaInfoEdit from './pages/Conta/ContaInfoEdit';
import Documentos from './pages/Documentos/Documentos';
import EmailConfirmacao from './pages/EmailConfirmacao/EmailConfirmacao';
import Home from './pages/Home/Home';
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/LoginRegister/Login';
import NewPassword from './pages/LoginRegister/NewPassword';
import Register from './pages/LoginRegister/Register';
import ResetPassword from './pages/LoginRegister/ResetPassword';
import MeusEnvios from './pages/MeusEnvios/MeusEnvios';
import Notificacoes from './pages/Notificacoes/Notificacoes';
import Perfil from './pages/Perfil/Perfil';
// import PostagensConferidas from './pages/PostagensConferidas/PostagensConferidas';
import Tratativas from './pages/Tratativas/Tratativas';
import WhatsApp from "./components/Footer/WhatsApp";
import MinhaLoja from './components/LojaForm/MinhaLoja';
import PostagensConferidas from './pages/PostagensConferidas/PostagensConferidas';


function App() {
  const [isLogged, setIsLogged] = useState(false);
  const userLogged = useSelector((state) => state.isUserLogged);
  const isUserRegisterSuccessMessage = useSelector((state) => state.isUserRegister);
  const isLojaModal = useSelector((state) => state.modal);

  useEffect(() =>{
    if(userLogged){
      setIsLogged(true);
    }
  }, [isLogged, userLogged, isUserRegisterSuccessMessage, isLojaModal])


  const callbackLogin = (usuario) => {
    if(usuario !== null){
      setIsLogged(true);
    }
	};

  return (
    <div className={isUserRegisterSuccessMessage || isLojaModal === true ? `Flexfrete is-clipped` : `Flexfrete`}>

    <div className={`App`}>
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <Header isLogged={isLogged} />
          {isLogged === true ? <SideMenuBar /> : null }
          <div className={isLogged === true ? `FlexFrete-content private` : `FlexFrete-content` }>
        <Switch>
              {
                isLogged === true ?
                (
                  <>
                  <Route path="/perfil" component={Perfil} />
                  <Route path="/" component={Home}  exact/>
                  <Route path="/home" component={Home} />
                  <Route path="/carrinho" component={CarrinhoDeCompras} />
                  <Route path="/calculadora" component={Calculadora} />
                  <Route path="/carteira" component={Carteira} />
                  <Route path="/configuracoes" component={Configs}>
                    <Route path="/configuracoes/conta" component={Conta} />
                    <Route path="/configuracoes/restaurar-senha" component={ContaEditPassword}/>
                    <Route path="/configuracoes/conta-editar" component={ContaInfoEdit}/>

                    {<Route path="/configuracoes/minha-loja" component={MinhaLoja} />}
                    {/* <Route path="/configuracoes/gerenciar-acessos/:id" component={GerenciarAcessos} /> */}
                    {/* <Route path="/configuracoes/new-loja" component={LojaFormNewPage} /> */}
                    {/* <Route path="/configuracoes/edit-loja/:id" component={LojaFormEditPage} /> */}
                    <Route path="/configuracoes/documentos" component={Documentos} />
                  </Route>
                  {/* <Route path="/integracoes" component={Integracoes} /> */}
                  <Route path="/envios" component={MeusEnvios} />
                  <Route path="/notificacoes" component={Notificacoes} />
                  {<Route path="/postagens" component={PostagensConferidas} />}
                  <Route path="/tratativas" component={Tratativas} />
                  <Route path="/confirmacaoInsercaoSaldo" component={ConfirmacaoInsercaoDeSaldo} />
                  </>
                ) : (
                  <>
                  <Route path = "/" component={LandingPage} exact={true} />
                  <Route path="/register" component={Register} />
                  <Route path="/login" >
                    <Login callbackLogin={callbackLogin} />
                  </Route>
                  <Route path="/recuperarSenha" component={ResetPassword} />
                  <Route path="/restauroSenha" component={NewPassword} />
                  <Route path="/validacao" component={EmailConfirmacao} />
                  </>
                )
              }
        </Switch>
          <WhatsApp />
          </div>
          <Footer isLogged={isLogged}/>
      </Router>
    </div>
    </div>
  );

}

export default App;
