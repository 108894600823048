import React, { useState } from 'react';
import DiferencasNosFretesHeader from '../../components/DiferencaNosFretes/DiferencaNosFretesHeader';
import DiferencaNosFretetesList from '../../components/DiferencaNosFretes/DiferencaNosFretetesList';
import { PostagensConferidasStyle } from './style';

function PostagensConferidas() {

  const [reloadList, setReloadList] = useState(false);

  return (
    <div className="page-container-style">
        <PostagensConferidasStyle>
          <h2 className="page-title">Diferenças nos Fretes</h2>

            <DiferencasNosFretesHeader onReloadList={setReloadList} />
            <DiferencaNosFretetesList reloadList={reloadList} onReloadList={setReloadList}  />
      </PostagensConferidasStyle>
    </div>
  );
}

export default PostagensConferidas;
