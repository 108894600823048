import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../../_actions/ActionCreators';
import DownloadService from '../../services/DownloadService';
import FreteService from '../../services/FreteService';
import DadosBaixarModal from '../DadosBaixarModal/DadosBaixarModal';
import FlexFreteSpinner from '../FlexFreteSpinner/FlexSpinner';
import DiferencaFrete from './DiferencaFrete';
import { DiferencaNosFretesListForm } from './style';

const freteService = new FreteService();
const downloadService = new DownloadService();

export default function DiferencaNosFretetesList({reloadList, onReloadList}) {
  const [showingItems, setShowingItems] = useState([false]);
  const [loading, setLoading] = useState(false);
  const showFormatModal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [fretes, setFretes] = useState(false);

  const [request, setRequest] = useState({
      dataInicio: '',
      dataFim: '',
      tipoPagamentoFrete: "DEBITO/CREDITO",
      statusPagamentoFrete: ""
  });

  const showHideElementCallback = (index)=>{
    const showAux = showingItems;

    const show = showingItems[index] ? false: true;
    showAux[index] = show;

    setShowingItems([...showAux]);
  }
  useEffect(() => {
    function callback(){
      setLoading(true);
      freteService.getFretePagamento(request, (err, sucess)=> {
        setLoading(false);
        if(err){
          console.log(err);
        }else{
          setFretes(sucess);
        }
      })
    }
    callback();
    // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(reloadList){
      onReloadList(false);
      setLoading(true);
      freteService.getFretePagamento(request, (err, sucess)=> {
        setLoading(false);
        if(err){
          console.log(err);
        }else{
          setFretes(sucess);
          onReloadList(false);
        }
      })
    }
  }, [reloadList, onReloadList, request]);

  const submitForm = (e, formato) =>{
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    freteService.getFretePagamento(request, (err,sucess)=>{
      setLoading(false);
      if(err){
        console.log(err)
      }else{
        if (formato){
          downloadService.downloadFile({label: "diferencas_frete", data: sucess, mime: formato});
          dispatch(ActionCreators.hideModal(false));
        }else{
          console.log(sucess)
          setFretes(sucess)
        }
      }
    });
  }
    return (
        <DiferencaNosFretesListForm>
          {
            showFormatModal === true ?
            (
              <DadosBaixarModal visivel={showFormatModal !== null} onChange={submitForm} />
            ) :""
          }
          {loading? <FlexFreteSpinner/>: ""}
              <form  onSubmit={e => { submitForm(e)}}>
                    <div>
                        <div className="formulario-titulo">
                            <h2 className="formulario-busca-titulo">Diferenças</h2>
                        </div>

                        <div className="formulario-busca-inputs ">
                            <div className="formulario-busca-input-area periodo">
                                <label htmlFor="dataInicio">
                                    Período de início
                                </label>
                                <input type="date" className="input"  name="dataIncio" onChange = { e => {setRequest({...request, dataInicio: e.target.value})} }/>

                            </div>
                            <div className="formulario-busca-input-area periodo">
                                <label htmlFor="dataInicio">
                                    Período de término
                                </label>
                                <input type="date" className="input"  name="dataIncio" onChange = { e => {setRequest({...request, dataFim: e.target.value})} }/>
                            </div>

                            <div className="formulario-busca-input-area">
                                <label htmlFor="transacao">Transação</label>
                                <div className="select">
                                    <select name="transacao" defaultValue={"DEBITO/CREDITO"} onChange={e => {setRequest({...request, tipoPagamentoFrete: e.target.value})} }>
                                        <option value="DEBITO/CREDITO">Todos</option>
                                        <option value="DEBITO">Débito</option>
                                        <option value="CREDITO">Crédito</option>
                                    </select>
                                </div>

                            </div>

                            <div className="formulario-busca-input-area">
                                <label htmlFor="status">Status</label>
                                <div className="select">
                                    <select name="status" defaultValue={""} onChange={e => {setRequest({...request, statusPagamentoFrete: e.target.value})} }>
                                        <option value="">Todos</option>
                                        <option value="ABERTO">Pendentes</option>
                                        <option value="QUITADO">Resolvidos</option>
                                    </select>
                                </div>

                            </div>

                        </div>
                        <div className="formulario-busca-input-area quantidade-fretes">
                            <button className="button buscar-button" type="submit">Buscar</button>
                        </div>
                        <div>
                          <div className="download-fretes">
                            <label htmlFor="selecionar_todos" className="checkbox">
                              <input type="checkbox" name="selecionar_todos" id="" />
                                Selecionar todos
                            </label>

                            <input type="button" value="Baixar tabela" className="button" onClick={e => { dispatch(ActionCreators.hideModal(true))}}/>

                          </div>
                        </div>

                    </div>
                </form>

            <ul>
                {fretes  ? fretes?.map((frete, index) => (
                  <DiferencaFrete frete={frete} showHideElementCallback={showHideElementCallback} show={showingItems[index]} index={index}/>
                )):""}
            </ul>
        </DiferencaNosFretesListForm>
    )
}
