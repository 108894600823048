import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ActionCreators } from '../../_actions/ActionCreators';
import CarrinhoService from "../../services/CarrinhoService";
import NumberNotification from '../FlexFreteNumberNotification/NumberNotification';
import { SideMenuStyle } from "./style";

function SideMenuBar() {
	const carrinhoService = new CarrinhoService();
	const [toggle, setToggle] = useState(false);
	const [showSubConfigs, setShowSubConfigs] = useState(false);
	const [config, setConfig] = useState(false);
	// const [notificacoesLength, setNotificacoesLength] = useState(0);

	const [showingConfig, setShowingConfig] = useState(false);

	let carrinhoLength = carrinhoService.getNumeroItensParaCompra() ? carrinhoService.getNumeroItensParaCompra() : ""
	let {freteCompradoComSucesso} = useSelector((state) => {
		return {
			freteCompradoComSucesso: state.freteCompradoComSucesso
		};
	});

	let location = useLocation();
	const dispatch = useDispatch();

	const handleSideBarVisibility = () => {
		setToggle(!toggle);

	};

	const closeSideBarWhenClickInOneOfTheMenus = () => {
		setToggle(false);
		setShowSubConfigs(false);
	}
	const handleSubConfigs =() =>{
	// Alterna o valor de showSubConfigs
	const newShowSubConfigs = showSubConfigs;

	// Atualiza showSubConfigs e garante que toggle seja true quando showSubConfigs for false
	setShowSubConfigs(newShowSubConfigs);

	// Se showSubConfigs for false, garante que toggle seja true
	if (!newShowSubConfigs) {
		setToggle(true);
	}

		setShowSubConfigs(!showSubConfigs);
	}

	useEffect(() =>{
		if(showSubConfigs === true){
			setToggle(true);
		}
		if(!toggle){
			setShowSubConfigs(false);
		}
	},[showSubConfigs, toggle])

	// const handleNotificacoesLength = () =>{
	// 	const notificacaoService = new NotificacaoService();

	// 	notificacaoService.obterQuantidadeDeNotificacoes((_error, sucess)=>{
	// 		if(sucess){
	// 			setNotificacoesLength(sucess.quantidade || 0)
	// 		}
	// 	})
	// }

	useEffect(() => {
		const carrinhoService = new CarrinhoService();
		carrinhoService.getCarrinho((erro, sucesso) => {
			if(erro){
				carrinhoService.setNumeroItensInfosParaCompra(0)
			}
			if(sucesso){
			  carrinhoService.setNumeroItensInfosParaCompra(sucesso.fretes.length)
			  carrinhoService.setCarrinhoInfosParaCompra(sucesso)
			}
		})

	 }, [])

	// useEffect(()=>{
	// 	const callHandleNotificationsLength = () =>{
	// 		handleNotificacoesLength();
	// 	}
	// 	callHandleNotificationsLength();
	// }, []);

	const activeConfig = () => {
		setConfig(true);
	}

	const hideConfig = () => {
		setConfig(false);
		setShowingConfig(false);
	}

	const closeHeaderSubMenu = () => {
		dispatch(ActionCreators.isHeaderSubMenuClicked(false))
	}

	const calculadora = () => {
		dispatch(ActionCreators.setFreteCotacaoSelecionada({}));

		if( Object.keys(freteCompradoComSucesso).length !== 0){
			dispatch(ActionCreators.setCarrinhoInformacoes({}));
			dispatch(ActionCreators.setFreteCompradoComSucesso({}));
			carrinhoService.deleteCalculadoraFormInfo();
		}
	}


	return (
		<>
		{
			location.pathname === '/perfil' ?
			(
				<>
				</>
			) :
			(

			<SideMenuStyle toggle={toggle} showSubConfigs={showSubConfigs} onClick={closeHeaderSubMenu}>
				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/home" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="home-outline"></ion-icon>
						<span className="side-menu-title">Home</span>
					</NavLink>
				</div>
				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/calculadora" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="calculator-outline"></ion-icon>
						<span className="side-menu-title">Calculadora</span>
					</NavLink>
				</div>
				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/carrinho" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="cart-outline">
						</ion-icon>
						{
							!carrinhoLength || carrinhoLength === 0 ? "" : <NumberNotification number={carrinhoLength} className="number-notification"/>
						}

						<span className="side-menu-title">Carrinho de compras</span>
					</NavLink>
				</div>
				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/envios" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="cube-outline"></ion-icon>
						<span className="side-menu-title">Meus fretes</span>
					</NavLink>
				</div>
				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/carteira" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="wallet-outline"></ion-icon>
						<span className="side-menu-title">Carteira Flex</span>
					</NavLink>
				</div>
				{<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/postagens" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="checkbox-outline"></ion-icon>
						<span className="side-menu-title">Diferenças nos Fretes</span>
					</NavLink>
				</div>}
				{/* <div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/integracoes" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="contract-outline"></ion-icon>
						<span className="side-menu-title">Integrações</span>
					</NavLink>
				</div> */}
				<div className={`${config === true ? 'configs-side-open' : 'configs-side'}`} onClick={handleSubConfigs}>
				<span className={"configuracao-link" + (showingConfig ? " selected-item" : "")} onClick={activeConfig}>
					<ion-icon name="settings-outline"></ion-icon>
					<span className="side-menu-title">Configurações</span>
					<span className="config-chevron" onClick={handleSubConfigs}>
					{showSubConfigs === true ? (<ion-icon name="chevron-up-outline"></ion-icon>) : (<ion-icon name="chevron-down-outline"></ion-icon>)}
					</span>
				</span>
				</div>

				<div className={showSubConfigs ? "selected-subConfigs-items-open" : "selected-subConfigs-items"}>
				<div onClick={() => { closeSideBarWhenClickInOneOfTheMenus(); setShowingConfig(true); }}>
					<Link className="side-menu-title" to="/configuracoes/conta" onClick={calculadora}>Conta Flex</Link>
				</div>
				<div onClick={() => { closeSideBarWhenClickInOneOfTheMenus(); setShowingConfig(true); }}>
					<Link className="side-menu-title" to="/configuracoes/documentos" onClick={calculadora}>Documentos</Link>
				</div>
				<div onClick={() => { closeSideBarWhenClickInOneOfTheMenus(); setShowingConfig(true); }}>
					<Link className="side-menu-title" to="/configuracoes/minha-loja">Minha Loja</Link>
				</div>
				</div>

				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<a href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="chatbubble-ellipses-outline"></ion-icon>
						<span className="side-menu-title">Suporte</span>
					</a>
				</div>
				<div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>

					{/* <NavLink to="/notificacoes" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="notifications-outline">10</ion-icon>
						{
						notificacoesLength?
							<NumberNotification number={notificacoesLength} className="number-notification"/> :""
						}
						<span className="side-menu-title">Notificações</span>
					</NavLink> */}
				</div>
				{/* <div onClick={()=>{hideConfig (); closeSideBarWhenClickInOneOfTheMenus();}}>
					<NavLink to="/tratativas" activeClassName="selected-item" className="sidebar-link" onClick={calculadora}>
						<ion-icon name="warning-outline"></ion-icon>
						<span className="side-menu-title">Tratativas</span>
					</NavLink>
				</div> */}
				<div className="expanded-sidebar" onClick={handleSideBarVisibility}>
					{toggle === false ? (
						<p>
							<ion-icon name="chevron-forward-outline"></ion-icon>
						</p>
					) : (
						<p>
							<ion-icon name="chevron-back-outline"></ion-icon>
						</p>
					)}
				</div>
			</SideMenuStyle>
			)
		}
		</>
	);


}

export default SideMenuBar;
