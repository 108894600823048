import React, { useEffect, useState } from 'react';
import AssinaturaService from '../../services/AssinaturaService';
import FreteService from '../../services/FreteService';
import Modal from '../FlexFreteModal/Modal';
import FlexSpinner from '../FlexFreteSpinner/FlexSpinner';
import InserirSaldo from '../InserirSaldo/InserirSaldo';
import { DiferencasNosFretesHeaderStyle } from './style';

const freteService = new FreteService();
const assinaturaService = new AssinaturaService();


export default function DiferencasNosFretesHeader({ onReloadList }) {

    const [valorEmAberto, setValorEmAberto] = useState({
        debito: 0,
        credito: 0,
    });
    const [saldoAtual, setSaldoAtual]= useState({});

    const [showModal, setShowModal] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [showInserirCreditosComponent, setInserirCreditosComponent] = useState(false);

    useEffect(() => {
        const callback = ()=>{
            setIsLoading(true);
            freteService.getValorEmAberto((err, sucess)=>{

                if(err){
                    setIsLoading(false);
                }else{
                    setValorEmAberto({debito:sucess.debito,credito:sucess.credito});
                    assinaturaService.loadAssinaturaSelecinadaHome((err, sucess)=>{
                        setIsLoading(false);
                        if(err){
                            console.log(err)
                        }else{
                            setSaldoAtual({sucess})
                        }
                    })
                    setIsLoading(false);
                }
            })

        }
        setIsLoading(false);
        callback()
    }, [])

    function submitRequest(tipoQuitacao, valor){

        if(valor === 0){
            if(tipoQuitacao === false){
                setShowModal({
                    titulo: 'Solicitação invalida.',
                    mensagem: 'Nenhum valor a pagar.',
                    show: true
                });
                return;
            }
            if(tipoQuitacao === true){
                setShowModal({
                    titulo: 'Solicitação invalida.',
                    mensagem: 'Nenhum valor a receber.',
                    show: true
                });
                return;
            }
        }

        const request = {
            tipoQuitacao
        }



        if (tipoQuitacao === false && saldoAtual < valorEmAberto.debito){
            setShowModal({
                titulo: 'Saldo insuficiente.',
                mensagem: 'Por favor, insira mais saldo para prosseguir com a requisição.',
                show: true
            });
            setInserirCreditosComponent(true);
            return;
        }
        setIsLoading(true);
        assinaturaService.atualizarSaldo(request, (err,sucess)=>{
            setIsLoading(false);
            if(err){
                setShowModal({
                    titulo: 'Falha ao processar a requisição.',
                    mensagem: 'Ocorreu um problema ao solicitar a correção do saldo.',
                    show: true

                });
            }else{
                onReloadList(true);
                setShowModal({
                    titulo: 'Atualização de saldo',
                    mensagem: 'Solicitação de correção de saldo efetuada com sucesso.',
                    show: true

                })
                setIsLoading(true);
                freteService.getValorEmAberto((err, sucess)=>{

                if(err){
                    setIsLoading(false);
                }else{
                    setValorEmAberto({debito:sucess.debito,credito:sucess.credito});
                    assinaturaService.loadAssinaturaSelecinadaHome((err, sucess)=>{
                        setIsLoading(false);
                        if(err){
                            console.log(err)
                        }else{
                            setSaldoAtual({sucess})
                        }
                    })
                    setIsLoading(false);
                }
            })
            }
        })
    }

    const hideModal = () => {
        setShowModal({});
    }
    return (
        <>
            <DiferencasNosFretesHeaderStyle>
                {
                    showModal.show?
                    (
                    <Modal callbackModal={hideModal} visivel={showModal.show} titulo={showModal.titulo} conteudo={showModal.mensagem} />
                    ) : null
                }
                {loading? <FlexSpinner/> :""}
                <div className="action-area" >
                    <span>
                        Crédito: <strong>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorEmAberto.credito)}</strong>
                    </span>
                    <button className="button" onClick={e => {submitRequest(true, valorEmAberto.credito)}}>
                        Resgatar
                    </button>
                </div>
                <div className="action-area" >
                    <span>
                        Débito: <strong>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorEmAberto.debito) }</strong>
                    </span>
                    <button className="button" onClick={e => {submitRequest(false, valorEmAberto.debito)}}>
                        Pagar
                    </button>
                </div>

            </DiferencasNosFretesHeaderStyle>

            {
                showInserirCreditosComponent?
                (
                    <InserirSaldo />
                ) :""
            }
        </>
    )
}
