import React, { useState } from 'react'
import { DiferencaFreteDiferencaValue, DiferencaFreteStyle } from './style'
import ModalEnvios from '../Modal/ModalEnvios'
import DetalhamentoFrete from './DetalhamentoFrete';

export default function DiferencaFrete({ frete, showHideElementCallback, show, index }) {

    const [showModal, setShowModal] = useState(false);

    return (
        <DiferencaFreteStyle>
            <header className="frete-cabecalho">
                <div className="diferenca-frete">
                    <div className="show-frete-mobile" onClick={e => { showHideElementCallback(index) }} >
                        <ion-icon name={show ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                    </div>
                    <span className="header-label">Transportadora: </span>
                    <strong>{frete?.transportadora?.nomeTransportadora}</strong>
                </div>

                <div className="diferenca-frete">
                    <span className="header-label">Destinatário: </span>
                    <strong>{frete?.destinatario?.nome}</strong>
                </div>

                <div className="diferenca-frete">
                    <span > Cod. Rastreio: </span>
                    <strong>{frete?.codigoEncomenda || ""}</strong>
                </div>

                <div className="diferenca-frete">
                    <span> Diferenças:</span>
                    <DiferencaFreteDiferencaValue isPositive={frete.diferencaValor >= 0}>
                        {frete.diferencaValor < 0 ? "-" : "+"}R${Math.abs(frete.diferencaValor).toFixed(2)}
                    </DiferencaFreteDiferencaValue>
                </div>

                <div className="show-frete-desktop" onClick={e => { showHideElementCallback(index) }} >
                    <ion-icon name={show ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                </div>
            </header>

            {
                show ?
                    (
                        <div className="frete-container">
                            <button className="button detalhamento"
                                onClick={e => { setShowModal(true) }}
                            >
                                Detalhamento do frete
                            </button>

                            <ModalEnvios
                                visivel={showModal}
                                onChange={(e, confirm) => { setShowModal(false) }}
                                titulo="Detalhamento do frete"
                                labelCancelButton="Fechar"
                            >
                               <DetalhamentoFrete frete={frete}></DetalhamentoFrete>
                            </ModalEnvios>

                            <p className="frete-alerta-mensagem">Seu frete sofreu diferença no valor devido a divergência dos seguintes itens:</p>
                            <div className="frete-diferencas">

                                {
                                    frete.dimensoesTransportadora ? (
                                        <div className="frete-diferenca-area">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Cotado</th>
                                                        <th>Postado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Comprimento</td>
                                                        <td>{frete.dimensoes?.comprimentoPacote} cm</td>
                                                        <td className={frete.dimensoesTransportadora?.comprimentoPacote > frete.dimensoes?.comprimentoPacote ? "has-text-danger" : "has-text-success"}>{frete.dimensoesTransportadora?.comprimentoPacote} cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Largura</td>
                                                        <td>{frete.dimensoes?.larguraPacote} cm</td>
                                                        <td className={frete.dimensoesTransportadora?.larguraPacote > frete.dimensoes?.larguraPacote ? "has-text-danger" : "has-text-success"}>{frete.dimensoesTransportadora?.larguraPacote} cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Altura</td>
                                                        <td >{frete.dimensoes?.alturaPacote} cm</td>
                                                        <td className={frete.dimensoesTransportadora?.alturaPacote > frete.dimensoes?.alturaPacote ? "has-text-danger" : "has-text-success"} >{frete.dimensoesTransportadora?.alturaPacote} cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Peso</td>
                                                        <td>{frete.dimensoes?.pesoPacote.toString().replace('.', ',')} kg</td>
                                                        <td className={frete.dimensoesTransportadora?.pesoPacote > frete.dimensoes?.pesoPacote ? "has-text-danger" : "has-text-success"}>
                                                            {frete.dimensoesTransportadora?.pesoPacote.toString().replace('.', ',')} kg</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="frete-diferenca-area">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Cotado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Comprimento</td>
                                                        <td>{frete.dimensoes?.comprimentoPacote} cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Largura</td>
                                                        <td>{frete.dimensoes?.larguraPacote} cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Altura</td>
                                                        <td>{frete.dimensoes?.alturaPacote} cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Peso</td>
                                                        <td>{frete.dimensoes?.pesoPacote.toString().replace('.', ',')} cm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }



                            </div>
                        </div>

                    ) : ""
            }



        </DiferencaFreteStyle>
    )
}
