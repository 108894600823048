import styled from 'styled-components';

export const DiferencasNosFretesHeaderStyle = styled.div`
    background-color: #fff;
    padding: 25px 0;
    border-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 900px;
    min-width: 360px;
    .button{
        background: linear-gradient(267deg, rgb(131, 43, 146) 39%, rgb(160, 69, 176) 100%);
        border: none;
        color: white;
        font-weight: 600;
        letter-spacing: 0.2px;
        width: 120px;
    }
    .button:hover{
        opacity: 0.8;
    }
    .action-area{
        display: flex;
        align-items: center;
        span{
            margin-right: 20px;
        }
    }

    @media(max-width: 1280px){
        flex-direction: column;
        width: fit-content;
        padding: 10px 50px;
        min-width: 390px;
        .action-area{
            margin: 10px 0;
            text-align: left;
        }
    }
    @media(max-width: 525px){
        min-width: 300px;
        max-width: 300px;
    }
`;

export const DiferencaNosFretesListForm = styled.div`
    width: 100%;
    max-width: 900px;
    background-color: #fff;
    margin-top: 40px;
    padding: 30px;
    border-radius: 15px;
    .formulario-titulo{
        text-align: left;
        h2{
            font-weight: bold;
        }
        margin-bottom: 30px;
    }
    .formulario-busca-inputs{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
        .formulario-busca-input-area{
            text-align: left;
            max-width: 200px;
        }
        select, .select{
            width: 100%;
        }

    }
    .download-fretes{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        label{
            margin-right: 20px;
            input{
                margin-right: 10px;
            }
        }
        .button{
            color: rgba(136,48,152,1);
            font-weight: bold;
        }
    }
    .button.buscar-button{
        background: linear-gradient(267deg, rgb(131, 43, 146) 39%, rgb(160, 69, 176) 100%);
        border: none;
        color: white;
        font-weight: 600;
        letter-spacing: 0.2px;
        width: 120px;
    }
    .button.buscar-button:hover{
        opacity: 0.8;
    }
    @media(max-width: 1200px){
        width: fit-content;
        max-width: 390px;
        min-width: 390px;

        .formulario-busca-inputs{
            flex-direction: column;
            align-items: flex-start;
            .formulario-busca-input-area{
                text-align: left;
                max-width: 280px;
                width: 100%;
            }
            .formulario-busca-input-area.periodo{
                text-align: left;
                max-width: 200px;
                width: 100%;
            }
            select, .select{
                width: 100%;
            }
        }
    }
    @media(max-width: 525px){
        .formulario-busca-inputs{

            .formulario-busca-input-area{
                max-width: 200px;
            }
        }
    }
    @media(max-width: 525px){
        min-width: 300px;
        max-width: 300px;
    }
`

export const DiferencaFreteStyle = styled.li`
    margin: 30px 0;
    padding-left: 25px;
    .frete-cabecalho{
        display: flex;
        justify-content: space-between;
        .show-frete-desktop{
            display:block;
            cursor: pointer;
            width: 20px;
        }
        .show-frete-mobile{
            display:none;
        }
        .diferenca-frete{
            display: flex;
            flex-direction: column;
            margin-left: 0.5px;
            padding: 0.3em;
            span:first-child{
                margin-right: 5px;
            }

        }
    }

    .button{
        background: linear-gradient(267deg, rgb(131, 43, 146) 39%, rgb(160, 69, 176) 100%);
        border: none;
        color: white;
        font-weight: 600;
        letter-spacing: 0.2px;
    }
    .button:hover{
        opacity: 0.8;
    }

    .detalhamento{
        margin-top: 10px;
    }


    .table{

    }

    .table tr td{
        text-align: center;
    }

    .table tr td:last-child{
        font-weight: bold;
    }


    .table tr td:first-child{
        text-align: right;
    }
    .frete-container{
        text-align: left;
    }
    .frete-diferencas{
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
       .frete-diferenca-area >div{
            white-space: nowrap;
            display: flex;
       }
       .frete-diferenca-area{
            div{
                width: calc(100% + 10px);
                justify-content: space-between;
            }

       }
    }
    .diferenca-valor-informada{
        text-decoration: line-through;
        color: red;
    }
    .frete-alerta-mensagem{
        color: red;
        text-align: left;
        margin: 30px 0;
        font-size: 1.5rem;
    }
    @media(max-width: 1200px){
        min-width: 360px;
        .frete-cabecalho{
            flex-direction: column;
            align-items: flex-start;
            .diferenca-frete{
                position: relative;
                margin-bottom: 10px;
            }
            .show-frete-mobile{
                display: block;
                position: absolute;
                cursor: pointer;
                left: -30px;
                width: 20px;
            }
            .show-frete-desktop{
                display: none;
            }
        }

        .frete-alerta-mensagem{
            max-width: 300px;
            white-space: pre-line;
        }
        .frete-diferencas{
            flex-direction: column;
       }
       .frete-diferenca-area{
            padding-right: 20px;
            margin: 10px 0;
            div{
                width: 100%;
            }

       }
       .diferenca-valor-informada, .diferenca-valor{
            //margin-right: 20px;
        }
    }
    @media(max-width: 525px){
        .frete-alerta-mensagem{
            max-width: 200px;
        }
    }

    .detalhamento-frete{
        max-height: 60vh;
        overflow-y: auto;
    }

    .modal-background {
        background-color: rgba(10, 10, 10, .3);
    }
`;

export const DiferencaFreteDiferencaValue = styled.span`
    font-weight: bold;
    color: ${props => props.isPositive? "green": "red"};
`
