import React from 'react'
import { MeusEnviosFreteStyle } from '../MeusEnviosFreteList/style'
import { cepMask, cnpjMask, cpfMask, moneyMask, telefoneMask, notaFiscalMask } from '../../utils/Masks';

export default function DetalhamentoFrete({ frete }) {


	return (
		<div className='detalhamento-frete'>
			<MeusEnviosFreteStyle>
				<header className="meus-envios-frete-cabecalho-postado">
					<div>
						<span className="header-label">Transportadora: </span>
						<strong>
							{frete?.transportadora?.nomeTransportadora}
						</strong>
					</div>

					<div>
						<span className="header-label">Destinatário: </span>
						<strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
					</div>

					<div>
						<span className="header-label">Cód.: </span>
						<strong>{frete?.codigoEncomenda}</strong>
					</div>
				</header>

				<div className="meus-envios-frete-container">

					<dir className="meus-envios-frete-section">
						<div className="meus-envios-frete-container-area">
							<div className="container-area-line">
								{
									frete.statusFrete === "CANCELADO" ?
										(
											<>

												<strong className="line-label">Data de cancelamento:</strong>
												<span>{frete?.dataCancelamento || ""}</span>

											</>

										) : ""

								}
							</div>
							<div className="container-area-line">
								{
									frete.statusFrete === "CANCELADO" ?
										(
											<>
												<strong className="line-label">Justificativa cancelamento:</strong>
												<span>{frete?.justificativaCancelamento || ""}</span>

											</>

										) : ""

								}
							</div>
							<div className="container-area-line">
								<strong className="line-label">Cod. Rastreio:</strong>
								<span>{frete?.codigoEncomenda || ""}</span>
							</div>
							<div className="container-area-line">
								<strong className="line-label">Valor calculado:</strong>
								<span>{moneyMask(frete?.valorEncomenda.toFixed(2)) || ""}</span>
							</div>
							{
								frete?.valorRastreadoTransportadora != null ?
									(
										<div className="container-area-line">
											<strong className="line-label">Valor cobrado na postagem:</strong>
											<span>{moneyMask(frete?.valorRastreadoTransportadora.toFixed(2)) || ""}</span>
										</div>
									) : ""
							}
							<div className="container-area-line">
								<strong className="line-label">Prazo de entrega:</strong>
								<span>{frete.tempoMinimo || ""} - {frete.tempoMaximo || ""} dias úteis </span>
							</div>
							<div className="container-area-line">
								<strong className="line-label">Valor Segurado:</strong>
								<span>{moneyMask(frete?.valorSeguro.toFixed(2)) || ""}</span>
							</div>
							<div className="container-area-line">
								<strong className="line-label">Tipo de pacote</strong>
								<span>{frete?.formato || ""}</span>
							</div>

						</div>
						<div className="meus-envios-frete-container-area">

							<div className="container-area-line">
								{
									frete?.enderecoAgencia ?
										(
											<>
												<strong className="line-label">Agência:</strong>
												<span>{frete?.enderecoAgencia || ""}</span>
											</>
										) : ""

								}
							</div>

							<div className="container-area-line">
								<strong className="line-label">Coleta:</strong>
								<span>{frete?.cotacao?.coleta ? "Sim" : "Não"}</span>
							</div>

							<div className="container-area-line">
								<strong className="line-label">Aviso de recebimento:</strong>
								<span>{frete?.cotacao?.avisoRecebimento ? "Sim" : "Não"}</span>
							</div>

							<div className="container-area-line">
								<strong className="line-label">Data:</strong>
								<span>{frete?.data || ""}</span>
							</div>

						</div>


					</dir>


					<div className="meus-envios-frete-container">

						<div className="meus-envios-frete-section">

							<div className="meus-envios-frete-container-area-pessoa">
								<div className="pessoa-titulo">
									<h4>Remetente</h4>
								</div>
								<div className="container-area-line">
									<strong>{frete?.remetente?.nome || ""} {frete?.remetente?.sobrenome || ""}</strong>
								</div>

								<div className="container-area-line">
									<strong className="line-label">CPF / CNPJ:</strong>
									<span>{frete?.remetente?.cpfCnpj.length === 11 ? cpfMask(frete?.remetente?.cpfCnpj) : cnpjMask(frete?.remetente?.cpfCnpj)}</span>
								</div>

								<div className="container-area-line">
									<strong className="line-label">Tel:</strong>
									<span>{frete?.remetente?.telefone ? telefoneMask(frete.remetente.telefone) : ""}</span>
								</div>

								<div className="container-area-line">
									<strong className="line-label">Email:</strong>
									<span>{frete?.remetente?.email}</span>
								</div>

								<div className="container-area-line">
									<strong className="line-label">Rua:</strong>
									<span>
										{frete?.remetente?.endereco?.logradouro}, {frete?.remetente?.endereco?.numero}
										{
											frete?.remetente?.endereco?.complemento ? "," : ""
										}
										{frete?.remetente?.endereco?.complemento}
									</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">Bairro:</strong>
									<span>{frete?.remetente?.endereco?.bairro}</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">Cidade/Estado:</strong>
									<span>{frete?.remetente?.endereco?.cidade} - {frete?.remetente?.endereco?.estado}</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">CEP:</strong>
									<span>{frete?.remetente?.endereco?.cep ? cepMask(frete?.remetente?.endereco?.cep) : ""}</span>
								</div>
							</div>

							<div className="meus-envios-frete-container-area-pessoa">
								<div className="pessoa-titulo">
									<h4>Destinatário</h4>
								</div>
								<div className="container-area-line">
									<strong>{frete?.destinatario?.nome || ""} {frete?.destinatario?.sobrenome || ""}</strong>
								</div>

								<div className="container-area-line">
									<strong className="line-label">CPF / CNPJ:</strong>
									<span className="line value">{frete?.destinatario?.cpfCnpj.length === 11 ? cpfMask(frete?.destinatario?.cpfCnpj) : cnpjMask(frete?.destinatario?.cpfCnpj)}</span>
								</div>

								<div className="container-area-line">
									<strong className="line-label">Tel:</strong>
									<span>{frete?.destinatario?.telefone ? telefoneMask(frete?.destinatario?.telefone) : ""}</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">Email:</strong>
									<span>{frete?.destinatario?.email}</span>
								</div>

								<div className="container-area-line">
									<strong className="line-label">Rua:</strong>
									<span>
										{frete?.destinatario?.endereco?.logradouro}, {frete?.destinatario?.endereco?.numero}
										{
											frete?.destinatario?.endereco?.complemento ? "," : ""
										}
										{frete?.destinatario?.endereco?.complemento}
									</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">Bairro:</strong>
									<span>{frete?.destinatario?.endereco?.bairro}</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">Cidade/Estado:</strong>
									<span>{frete?.destinatario?.endereco?.cidade} - {frete?.destinatario?.endereco?.estado}</span>
								</div>
								<div className="container-area-line">
									<strong className="line-label">CEP:</strong>
									<span>{frete?.destinatario?.endereco?.cep ? cepMask(frete?.destinatario?.endereco?.cep) : ""}</span>
								</div>
							</div>
						</div>

					</div>

					{
						frete.notaFiscal ?
							<div className="container-nota-fiscal">
								<div className="nota-fiscal-titulo">
									<h4>Nota Fiscal</h4>
								</div>
								<div className='nota-fiscal'>
									<div className="container-area-line">
										<strong className="line-label">Valor da nota fiscal:</strong>
										<span>{moneyMask(frete.notaFiscal.valorNotaFiscal.toFixed(2))}</span>
									</div>
									<div className="container-area-line">
										<strong className="line-label">N&uacute;mero da nota fiscal:</strong>
										<span>{notaFiscalMask(frete.notaFiscal.numeroDanfCte)}</span>
									</div>
									<div className="container-area-line">
										<strong className="line-label">CFOP:</strong>
										<span>{notaFiscalMask(frete.notaFiscal.cfop)}</span>
									</div>

								</div>
							</div>
							: null
					}


					<div className="container-volumes-etiqueta">
						<div className="volumes">
							<strong>Volume</strong>
							<div>
								<p>C {frete?.dimensoes?.comprimentoPacote}cm L {frete?.dimensoes?.larguraPacote}cm A {frete?.dimensoes?.alturaPacote}cm C</p>
								<p>Peso {frete?.dimensoes?.pesoPacote}Kg</p>
							</div>
						</div>
					</div>
					<div className="rastreio-frete-container-area">
						{
							frete.ocorrencias != null && frete.ocorrencias.length > 0 ?
								(
									<div className="pessoa-titulo">
										<h4>Acompanhamento do frete</h4>
									</div>

								)
								: ""
						}

						{
							frete.ocorrencias != null && frete.ocorrencias.length > 0 ?
								frete.ocorrencias.map((ocorrencia, index) =>
								(
									<div className="meus-envios-frete-section">
										<div className="meus-envios-frete-container-area">
											<div className="container-area-line">
												<strong className="line-label">Status da transportadora:</strong>
												<span>{ocorrencia?.statusFreteTransportadora.trim()}</span>
											</div>
										</div>
										<div className="meus-envios-frete-container-area">
											<div className="container-area-line">
												<strong className="line-label">Agência:</strong>
												<span>{ocorrencia?.agencia} - {ocorrencia?.dataOcorrencia}</span>
											</div>
										</div>
									</div>
								)
								) : ""
						}


					</div>


				</div>

			</MeusEnviosFreteStyle>
		</div>
	)
}
